<template>
  <div>
    <vs-prompt
      :accept-text="$t('Accept')"
      :cancel-text="$t('Cancel')"
      :title="$t('Control_Product')"
      @accept="deleteAllBigProducts"
      :active.sync="askDeleteAllBigProduct"
      color="dark"
    >
      <div class="con-exemple-prompt">
        <span>
          {{ $t("Design_Message_4") }}
        </span>
      </div>
    </vs-prompt>
    <vs-prompt
      :accept-text="$t('Accept')"
      :cancel-text="$t('Cancel')"
      :title="$t('Control_Product')"
      @accept="removeProduct"
      :active.sync="wrongProductEnable"
      color="dark"
    >
      <div class="con-exemple-prompt">
        <span> {{ wrongProductTitle }} {{ $t("Design_Message_5") }} </span>
      </div>
    </vs-prompt>
    <vs-prompt
      :accept-text="$t('Accept')"
      :cancel-text="$t('Cancel')"
      :title="$t('Remove_All_Products')"
      @accept="clearAllProduct"
      :active.sync="clearAllConfirm"
      color="dark"
    >
      <div class="con-exemple-prompt">
        <span>{{ $t("Design_Message_6") }}</span>
      </div>
    </vs-prompt>
    <vs-prompt
      :accept-text="$t('Accept')"
      :cancel-text="$t('Cancel')"
      :title="$t('Confirm_Purchase')"
      @accept="controlOrder"
      :active.sync="confirmPurchaseEnable"
      color="dark"
    >
      <div class="con-exemple-prompt">
        <p>
          {{ $t("Design_Message_7") }} {{ getTotalPrice() }}
          {{ $t("Design_Message_8") }}
        </p>
      </div>
    </vs-prompt>
    <!-- IF CART HAVE ITEMS -->
    <div class="vx-row p-2 forNormalScreen">
      <!-- LEFT COL -->
      <div class="vx-col lg:w-3/4 w-full">
        <vs-row class="mb-3 p-1 cartList pt-2 pb-2" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="1"
            vs-sm="2"
          ></vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
          >
            <b class="cartTitle">{{ $t("Product_ID") }}</b>
          </vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="2"
            vs-sm="3"
          >
            <b class="cartTitle">{{ $t("Quantity") }}</b>
          </vs-col>

          <vs-col
            class="bigData"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="1"
          >
            <b class="cartTitle bigData">14K ({{ $t("Gr") }}.)</b>
          </vs-col>
          <vs-col
            class="bigData"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="1"
          >
            <b class="cartTitle bigData">
              21K ({{ $t("Gr") }}.)
              <br />
            </b>
          </vs-col>
          <vs-col
            class="bigData"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="1"
          >
            <b class="cartTitle bigData">{{ $t("Resin") }} ({{ $t("Gr") }}.)</b>
          </vs-col>

          <vs-col
            class="bigData"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="1"
          >
            <b class="cartTitle bigData">{{ $t("Total") }} ({{ $t("Gr") }}.)</b>
          </vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="1"
            vs-sm="3"
          >
            <b class="cartTitle">{{ $t("Total") }} {{ $t("Credit") }}</b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="1"
            vs-sm="1"
          >
            <vs-button
              :disabled="readAndAccept"
              @click="clearAllConfirm = true"
              class="clearAllButton"
              size="small"
              color="dark"
            >
              {{ $t("Clear_All") }}
            </vs-button>
          </vs-col>
        </vs-row>

        <div>
          <div v-for="(item, index) in shopItem" :key="index">
            <CartList
              @removeItem="checkSize()"
              :isBigProduct="isBigProduct"
              :item="item"
              :orderConfirm="readAndAccept"
            ></CartList>
          </div>
        </div>
      </div>

      <!-- RIGHT COL -->
      <div class="vx-col lg:w-1/4 w-full">
        <vx-card v-if="!isThereAnySize" class="mb-4">
          <span class="text-black">{{ $t("Select_Machine") }}</span>
          <v-select
            v-if="haveMachine"
            :disabled="readAndAccept || machinesOption.length == 1"
            @input="controlProductAndMachineSizes"
            class="mt-2"
            v-model="selectedMachine"
            placeholder="Machines"
            :options="machinesOption"
          >
          </v-select>
          <vs-alert :active="machineErrorEnable" color="danger" class="mt-2">
            {{ machineErrorMessage }}</vs-alert
          >
        </vx-card>
        <vx-card class="mb-4">
          <span class="text-black">{{ $t("Note") }}</span>
          <vs-textarea
            :disabled="readAndAccept"
            rows="3"
            autofocus
            class="m-0 w-full"
            label-placeholder="Note"
            v-model="note"
            @input="saveNote"
          />
        </vx-card>
        <vx-card>
          <p class="font-semibold mb-3">{{ $t("Order Details") }}</p>
          <div class="flex justify-between font-semibold mb-3">
            <span>{{ $t("Total") }} {{ $t("Product_Unit") }}</span>
            <span>{{ getTotalProductUnit() }}</span>
          </div>
          <div class="flex justify-between font-semibold mb-3">
            <span>{{ $t("Total") }} {{ $t("Resin") }}</span>
            <span>{{ getTotalResin() }} {{ $t("Gr") }}</span>
          </div>

          <div class="flex justify-between font-semibold mb-3">
            <span>{{ $t("Total") }} {{ $t("Credit") }}</span>
            <span>{{ getTotalPrice() }}</span>
          </div>
          <vs-alert color="warning" title="Warning" v-if="controlCreditAlert">
            {{ $t("Credit_Message_6") }}
          </vs-alert>
          <vs-divider />
          <div
            style="cursor: pointer"
            @click="controlSelectedSize()"
            v-if="!isThereAnySize"
            class="flex flex-wrap items-center pb-6"
          >
            <vs-checkbox
              color="success"
              :disabled="this.selectedMachine == null || !allSizeAvabile"
              v-model="readAndAccept"
              >{{ $t("Confirm_Order") }}</vs-checkbox
            >
          </div>

          <vs-button
            v-if="!isThereAnySize"
            :disabled="!readAndAccept || placeOrderDisable"
            @click="confirmPurchaseEnable = true"
            class="w-full"
            >{{ $t("Place_Order") }}</vs-button
          >
          <vs-button v-else :to="{ path: '/cart_size' }" class="w-full mt-2">{{
            $t("Next").toUpperCase()
          }}</vs-button>
        </vx-card>
      </div>
    </div>
    <div class="forSmallScreen">
      <!-- RIGHT COL -->
      <div class="mb-2">
        <vx-card v-if="!isThereAnySize" class="mb-4">
          <span class="text-black">{{ $t("Select_Machine") }}</span>
          <v-select
            v-if="haveMachine"
            :disabled="readAndAccept || machinesOption.length == 1"
            @input="controlProductAndMachineSizes"
            class="mt-2"
            v-model="selectedMachine"
            placeholder="Machines"
            :options="machinesOption"
          >
          </v-select>
          <vs-alert :active="machineErrorEnable" color="danger" class="mt-2">
            {{ machineErrorMessage }}</vs-alert
          >
        </vx-card>
        <vx-card class="noteCard mb-2 p-0">
          <span class="text-black">{{ $t("Note") }}</span>
          <vs-textarea
            :disabled="readAndAccept"
            rows="3"
            autofocus
            class="m-0 w-full"
            label-placeholder="Note"
            v-model="note"
            @input="saveNote"
          />
        </vx-card>
        <vx-card>
          <p class="font-semibold mb-3">{{ $t("Order Details") }}</p>
          <div class="flex justify-between font-semibold mb-3">
            <span>{{ $t("Total") }} {{ $t("Product_Unit") }}</span>
            <span>{{ getTotalProductUnit() }}</span>
          </div>
          <div class="flex justify-between font-semibold mb-3">
            <span>{{ $t("Total") }} {{ $t("Resin") }}</span>
            <span>{{ getTotalResin() }} {{ $t("Gr") }}</span>
          </div>

          <div class="flex justify-between font-semibold mb-3">
            <span>{{ $t("Total") }} {{ $t("Credit") }}</span>
            <span>${{ getTotalPrice() }}</span>
          </div>
          <vs-alert color="warning" title="Warning" v-if="controlCreditAlert">
            {{ $t("Credit_Message_6") }}
          </vs-alert>
          <vs-divider />
          <div
            @click="controlSelectedSize()"
            v-if="!isThereAnySize"
            class="flex flex-wrap items-center pb-6"
          >
            <vs-checkbox
              color="success"
              :disabled="this.selectedMachine == null || !allSizeAvabile"
              v-model="readAndAccept"
              >{{ $t("Confirm_Order") }}</vs-checkbox
            >
          </div>

          <vs-button
            v-if="!isThereAnySize"
            :disabled="!readAndAccept || placeOrderDisable"
            @click="confirmPurchaseEnable = true"
            class="w-full"
            >{{ $t("Place_Order") }}</vs-button
          >
          <vs-button v-else :to="{ path: '/cart_size' }" class="w-full">{{
            $t("Next").toUpperCase()
          }}</vs-button>
        </vx-card>
      </div>
      <!-- LEFT COL -->
      <div>
        <vs-row class="mb-2 cartList pt-2 pb-2 pr-2" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="2"
          ></vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
            <b class="smallScreenFontSize">{{ $t("Product_ID") }}</b>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b class="smallScreenFontSize">{{ $t("Quantity") }}</b>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b class="smallScreenFontSize"
              >{{ $t("Total") }} {{ $t("Credit") }}</b
            >
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
            <vs-button
              :disabled="readAndAccept"
              @click="clearAllConfirm = true"
              class="clearAllButton"
              color="dark"
              >{{ $t("Clear_All") }}</vs-button
            >
          </vs-col>
        </vs-row>
        <div>
          <div v-for="(item, index) in shopItem" :key="index">
            <CartList
              @removeItem="checkSize()"
              :isBigProduct="isBigProduct"
              :item="item"
            ></CartList>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CartList from "./components/CartList";
import Api from "@/api/api";
import API from "@/api/config";

import vSelect from "vue-select";

export default {
  data() {
    return {
      haveMachine: false,
      machineErrorMessage: "",
      machineErrorEnable: false,

      askDeleteAllBigProduct: false,
      isBigProduct: false,

      wrongProductEnable: false,
      wrongProductId: null,
      wrongProductTitle: "",
      wrongProduct: null,

      showMachineArea: false,
      selectedMachine: null,
      machinesOption: [],
      allSizeAvabile: true,

      confirmPurchaseEnable: false,
      clearAllConfirm: false,
      placeOrderDisable: false,
      isThereAnySize: false,
      readAndAccept: false,
      note: "",
      totalPrice: 0,
    };
  },
  components: {
    CartList,
    vSelect,
  },
  computed: {
    controlCreditAlert() {
      if (this.getCredit - this.getTotalPrice() > 0) {
        return false;
      } else {
        return true;
      }
    },
    shopItem() {
      return this.$store.getters["designMarket/getShopItems"];
    },
    shopItemVisible() {
      return this.$store.getters["designMarket/getShopItemsVisible"];
    },
    getCredit() {
      return this.$store.state.designMarketCredit;
    },
    getMachineTypes() {
      return this.$store.getters["getMachineTypes"];
    },
  },
  watch: {
    getMachineTypes(newValue, oldValue) {
      this.getMachineTypes2(true);
    },
  },
  created() {
    if (this.shopItem.length == 0) {
      this.$vs.notify({
        title: this.$t("Warning"),
        text: this.$t("Design_Message_9"),
        color: "danger",
      });
      this.$router.push("/design_market").catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    }

    if (localStorage.getItem("note") != null) {
      this.note = localStorage.getItem("note");
    }

    this.getMachineTypes2();

    this.checkSize();
  },
  methods: {
    controlBasketProductUnit() {
      if (this.shopItem.length == 0) {
        this.$router.push("/design_market");
      }
    },
    deleteAllBigProducts() {
      var shopItemList = [...this.shopItem];
      for (let index = 0; index < shopItemList.length; index++) {
        const element = shopItemList[index];

        if (element.isBigProduct == true) {
          this.$store.dispatch("designMarket/removeItemInShop", element);
        }
      }
    },
    controlProductAndMachineSizes() {
      var selectedMac = null;

      if (this.selectedMachine == null) {
        return;
      }
      if (!this.haveMachine) {
        this.machineErrorMessage = this.$t("Design_Message_29");
        this.machineErrorEnable = true;
        return;
      }

      if (this.getMachineTypes.length > 0) {
        this.getMachineTypes.forEach((element) => {
          if (
            element.name == this.selectedMachine.label ||
            element.name == this.selectedMachine.name
          ) {
            selectedMac = element;
            this.machineErrorEnable = false;
          }
        });
      } else {
        this.machineErrorMessage = this.$t("Design_Message_29");
        this.machineErrorEnable = true;
        return;
      }

      var controlSize = true;

      this.shopItem.forEach((element) => {
        if (
          element.minGeneralWidth > selectedMac.general_width &&
          element.minGeneralHeight > selectedMac.general_height
        ) {
          element.isBigProduct = true;
          controlSize = false;
        } else {
          element.isBigProduct = false;
        }
      });

      if (!controlSize) {
        this.allSizeAvabile = false;
        this.$vs.notify({
          color: "warning",
          title: this.$t("Warning"),
          text: this.$t("Design_Message_10"),
        });
        this.askDeleteAllBigProduct = true;
      } else {
        this.allSizeAvabile = true;
      }
    },
    controlSelectedSize() {
      if (this.shopItem.length == 0) {
        this.$router.push("/design_market");
      }
      if (!this.haveMachine) {
        this.machineErrorMessage = this.$t("Design_Message_29");
        this.machineErrorEnable = true;
        return;
      }
      if (!this.isThereAnySize && this.selectedMachine == null) {
        this.machineErrorMessage = this.$t("Design_Message_11");
        this.machineErrorEnable = true;
        return true;
      } else if (!this.allSizeAvabile) {
        this.controlProductAndMachineSizes();
      } else {
        return false;
      }
    },
    saveNote() {
      localStorage.setItem("note", this.note);
    },
    getTotalProductUnit() {
      var productUnit = 0;
      this.shopItemVisible.forEach((element) => {
        productUnit += parseInt(element.quantity);
      });
      this.shopItem.forEach((element) => {
        productUnit += parseInt(element.quantity);
      });
      return productUnit;
    },
    clearAllProduct() {
      this.clearAllConfirm = false;
      this.$store.commit("designMarket/CLEAR_ITEM_SHOP");
      setTimeout(() => {
        this.$router.push("/design_market").catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        }, 1000);
      });
    },
    checkSize() {
      this.controlBasketProductUnit();
      var isSize = false;
      this.shopItem.forEach((element) => {
        if (element.size != "General") {
          isSize = true;
        }
      });

      if (isSize) {
        this.isThereAnySize = true;
      } else {
        this.isThereAnySize = false;
        this.controlProductAndMachineSizes();
      }
    },
    handleSendOrderList(status, data) {
      this.$vs.loading.close();
      this.placeOrderDisable = false;

      if (status == 201) {
        this.$store.dispatch(
          "updateDesignMarketCredit",
          (this.getCredit - this.totalPrice).toFixed(2)
        );
        localStorage.removeItem("note");
        this.$router.push("/design_orders");
        this.$store.dispatch("designMarket/clearItemShop");
        this.$vs.notify({
          color: "success",
          title: this.$t("Successful"),
          text: this.$t("Design_Message_12"),
        });
      } else if (status == 204) {
        localStorage.removeItem("note");
        this.$router.push("/design_orders");
        this.$store.dispatch("designMarket/clearItemShop");
        this.$vs.notify({
          color: "warning",
          title: this.$t("Design_Message_13"),
          text: this.$t("Design_Message_14"),
        });
      } else if (status == 400) {
        if (data.data.code == "410") {
          this.shopItem.forEach((element) => {
            if (parseInt(element.id) == parseInt(data.data.id)) {
              this.wrongProductEnable = true;
              this.wrongProductTitle = element.title;
              this.wrongProduct = element;
            }
          });
        }
      } else if (status == 403) {
        this.$vs.notify({
          color: "danger",
          title: this.$t("Design_Message_15"),
          text: this.$t("Design_Message_16"),
        });
      } else if (status == 500) {
        this.$vs.notify({
          color: "danger",
          title: this.$t("Warning"),
          text: this.$t("Error_Message_1"),
        });
      } else if (status == 0) {
        this.$vs.notify({
          color: "warning",
          title: this.$t("Warning"),
          text: this.$t("Internet_Connection_Message"),
        });
      }
    },
    removeProduct() {
      this.$store.dispatch("designMarket/removeItemInShop", this.wrongProduct);
    },
    controlOrder() {
      if (this.shopItem.length == 0) {
        this.$vs.notify({
          color: "danger",
          title: this.$t("Cart_Empty"),
          text: this.$t("Design_Message_17"),
        });

        this.$router.push("/designMarket").catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
        return;
      }

      this.controlMachinesRequest();
    },
    controlMachinesRequest() {
      Api.get(API.BASEURL + API.ME, this.handleControlMachinesRequest);
    },
    handleControlMachinesRequest(status, data) {
      if (status >= 200 && status < 300) {
        let index = data.machine_types.findIndex(
          (i) => i.name == this.selectedMachine.label
        );
        if (index != -1) {
          this.openConfirm();
        } else {
          this.selectedMachine = null;
          this.readAndAccept = false;
          this.$store.dispatch("setUserInfo", data);
          this.$vs.notify({
            title: this.$t("Warning"),
            text: this.$t("Design_Message_30"),
            color: "warning",
          });
          this.getMachineTypes2();
        }
      }
    },
    openConfirm() {
      this.placeOrderDisable = true;

      var unoptimizedList = this.shopItemVisible.concat(this.shopItem);
      var optimizedList = [];

      unoptimizedList.forEach((element) => {
        optimizedList[optimizedList.length] = {
          id: element.id,
          quantity: element.quantity,
          size: element.size,
        };
      });

      var products;
      this.getMachineTypes.forEach((machine) => {
        if (machine.name == this.selectedMachine.label) {
          this.selectedMachine = machine;
        }
      });

      products = {
        products: optimizedList,
        note: this.note,
        plate_width: parseInt(this.selectedMachine.general_width),
        plate_height: parseInt(this.selectedMachine.general_height),
        machine_type: this.selectedMachine.name,
      };

      this.totalPrice = this.getTotalPrice();
      Api.post(API.BASEURL + API.ORDERS, this.handleSendOrderList, products);
    },
    getTotalPrice() {
      var shopItems = this.$store.getters["designMarket/getShopItems"];
      var totalPrice = 0;

      shopItems.forEach((element) => {
        totalPrice += element.price * element.quantity;
      });

      return parseFloat(totalPrice.toFixed(2));
    },
    getTotalResin() {
      var shopItems = this.$store.getters["designMarket/getShopItems"];
      var totalResin = 0;

      shopItems.forEach((element) => {
        totalResin += element.resin_gr * element.quantity;
      });

      return parseFloat(totalResin.toFixed(2));
    },
    getMachineTypes2(changes = false) {
      if (this.getMachineTypes.length > 1) {
        this.haveMachine = true;
        this.showMachineArea = true;
        this.machineErrorEnable = false;
        this.machinesOption = [];
        this.getMachineTypes.forEach((machine, index) => {
          this.machinesOption[index] = {
            id: machine.name,
            label: machine.name,
          };
        });
      } else if (this.getMachineTypes.length == 1) {
        this.machinesOption = [];
        this.machinesOption[0] = {
          id: this.getMachineTypes[0].name,
          label: this.getMachineTypes[0].name,
        };
        this.machineErrorEnable = false;
        this.haveMachine = true;
        this.showMachineArea = false;
        this.selectedMachine = this.machinesOption[0];
      } else {
        this.haveMachine = false;
        this.machineErrorMessage = this.$t("Design_Message_29");
        this.machineErrorEnable = true;
        this.showMachineArea = true;
      }

      if (changes) {
        this.controlProductAndMachineSizes();
      }
    },
  },
};
</script>

<style>
.v-select .vs__dropdown-toggle .vs__actions .vs__clear {
  display: none;
}
.vs__dropdown-option--highlight {
  background: #c89e82 !important;
  color: #fff;
}
.btn-group button {
  padding: 5px 7px 5px 7px !important;
  border: 1px solid #c89e82;
}
@media screen and (min-width: 900px) {
  .forSmallScreen {
    display: none !important;
  }
}
@media screen and (max-width: 900px) {
  .forNormalScreen {
    display: none !important;
  }
}

.smallScreenFontSize {
  font-size: 11px;
}

.clearAllButton {
  font-weight: 800;
  font-size: 10px !important;
  padding: 5px 5px 5px 5px !important;
}
.cartTitle {
  font-weight: 900;
  font-size: 1rem;
}
</style>
