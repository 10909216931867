<template>
  <div>
    <vs-popup fullscreen :title="popupTitle" :active.sync="popupActive">
      <div style="position: relative">
        <img
          v-if="item.type == 'V'"
          style="width: 32px; height: 32px"
          class="vipFreeImage"
          src="@/assets/images/pages/vip64.png"
          alt=""
        />
        <img
          v-if="item.type == 'F'"
          style="width: 32px; height: 32px"
          class="vipFreeImage"
          src="@/assets/images/pages/free64.png"
          alt=""
        />
        <img class="popupImage" :src="popupImage" alt />
      </div>
    </vs-popup>
    <vs-prompt
      :accept-text="$t('Accept')"
      :cancel-text="$t('Cancel')"
      title="Remove the product"
      @accept="removeItemFromCart"
      :active.sync="removeConfirm"
      color="dark"
    >
      <div class="con-exemple-prompt">
        <span>{{ $t("Design_Message_1") }}</span>
      </div>
    </vs-prompt>
    <vs-row
      vs-type="flex"
      vs-align="center"
      class="mb-1 pb-1 pt-1 cartList forNormalScreen"
      :class="{ cartList: !item.isBigProduct, cartList2: item.isBigProduct }"
    >
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="1"
        vs-sm="2"
        style="position: relative"
      >
        <img
          v-if="item.type == 'V'"
          style="width: 16px; height: 16px"
          class="vipFreeImage"
          src="@/assets/images/pages/vip24.png"
          alt=""
        />
        <img
          v-if="item.type == 'F'"
          style="width: 24px; height: 24px"
          class="vipFreeImage"
          src="@/assets/images/pages/free24.png"
          alt=""
        />
        <img
          v-if="!imageErrorChangePhoto"
          class="avatar"
          @error="imageError()"
          :src="itemColon.thumbnail"
          alt
          @click="openPopupProductImage(itemColon.title, itemColon.image)"
        />
        <img
          v-else
          class="avatar"
          src="@/assets/images/pages/no_image.png"
          alt
        />
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="3"
        vs-sm="3"
      >
        <b class="bigData2">{{ itemColon.title }}</b>
      </vs-col>

      <vs-col
        class="input-number"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="2"
        vs-sm="3"
      >
        <vs-input-number
          :disabled="orderConfirm"
          :min="inputMin"
          size="medium"
          :max="inputMax"
          v-model="itemColon.quantity"
          @input="
            updateItemQuantitySameItem(
              itemColon.quantity,
              itemColon.id,
              itemColon.size,
              itemColon.type
            )
          "
          tabindex="1"
        ></vs-input-number>
      </vs-col>

      <vs-col
        class="bigData"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="1"
      >
        <p>
          {{
            parseFloat(
              (
                itemColon.quantity *
                itemColon.resin_gr *
                goldWeightMultipier.gold14K
              ).toFixed(2)
            )
          }}
        </p>
      </vs-col>
      <vs-col
        class="bigData"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="1"
      >
        <p>
          {{
            parseFloat(
              (
                itemColon.quantity *
                itemColon.resin_gr *
                goldWeightMultipier.gold21K
              ).toFixed(2)
            )
          }}
        </p>
      </vs-col>

      <vs-col
        class="bigData"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="1"
      >
        <p>{{ parseFloat(itemColon.resin_gr.toFixed(2)) }}</p>
      </vs-col>

      <vs-col
        class="bigData"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="1"
      >
        <p>
          {{ parseFloat((itemColon.quantity * itemColon.resin_gr).toFixed(2)) }}
        </p>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="1"
        vs-sm="2"
      >
        <b class="bigData2">{{
          parseFloat((itemColon.price * itemColon.quantity).toFixed(2))
        }}</b>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="1"
        vs-sm="2"
      >
        <vs-button
          :disabled="orderConfirm"
          radius
          @click="
            removeItem = itemColon;
            removeConfirm = true;
          "
          color="#FFFFFF"
        >
          <vs-icon icon="delete" color="dark" size="small"></vs-icon>
        </vs-button>
      </vs-col>
    </vs-row>
    <vs-row
      vs-type="flex"
      vs-align="center"
      class="mb-1 pb-1 pt-1 forSmallScreen cartList"
    >
      <vs-col
        style="position: relative"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="2"
      >
        <img
          v-if="item.type == 'V'"
          style="width: 16px; height: 16px"
          class="vipFreeImage"
          src="@/assets/images/pages/vip24.png"
          alt=""
        />
        <img
          v-if="item.type == 'F'"
          style="width: 24px; height: 24px"
          class="vipFreeImage"
          src="@/assets/images/pages/free24.png"
          alt=""
        />
        <vs-avatar size="medium" :src="itemColon.image" />
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
        <b class="smallScreenFontSize">{{ itemColon.title }}</b>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
        <vs-input-number
          :disabled="orderConfirm"
          min="1"
          class="smallScreenInputNumber"
          max="500"
          v-model="itemColon.quantity"
          @input="
            updateItemQuantitySameItem(
              itemColon.quantity,
              itemColon.id,
              itemColon.size,
              itemColon.type
            )
          "
        ></vs-input-number>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
        <b
          >${{
            parseFloat((itemColon.price * itemColon.quantity).toFixed(2))
          }}</b
        >
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
        <vs-button
          :disabled="orderConfirm"
          radius
          @click="
            removeItem = itemColon;
            removeConfirm = true;
          "
          color="#FFFFFF"
        >
          <vs-icon icon="delete" color="dark" size="small"></vs-icon>
        </vs-button>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inputMin: 1,
      inputMax: 500,
      //Popup
      popupTitle: "",
      popupActive: false,
      popupImage: null,

      imageErrorChangePhoto: false,
      removeConfirm: false,
      removeItem: null,
    };
  },
  props: ["item", "orderConfirm"],
  computed: {
    productToChooseSize() {
      if (
        this.itemColon.size != "General" &&
        this.itemColon.selectedSize[0] == 0 &&
        this.itemColon.selectedSize[1] == 0 &&
        this.itemColon.selectedSize[2] == 0 &&
        this.itemColon.selectedSize[3] == 0 &&
        this.itemColon.selectedSize[4] == 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    itemColon() {
      return this.item ? { ...this.item } : {};
    },
    goldWeightMultipier() {
      return this.$store.getters["designMarket/getGoldWeightMultipier"];
    },
  },
  methods: {
    openPopupProductImage(title, image) {
      this.popupTitle = title;
      this.popupImage = image;
      this.popupActive = true;
    },
    imageError() {
      this.imageErrorChangePhoto = true;
      return true;
    },
    removeItemFromCart() {
      this.removeConfirm = false;
      setTimeout(() => {
        this.$store.dispatch("designMarket/removeItemInShop", this.removeItem);
        this.$emit("removeItem");
      }, 100);
    },
    updateItemQuantitySameItem(quantity, id, size, type) {
      if (quantity >= 5) {
        this.$store.dispatch("designMarket/updateItemQuantity", {
          quantity: parseInt(quantity),
          id: id,
          size: size,
        });
      } else {
        this.$store.dispatch("designMarket/updateItemQuantity", {
          quantity: parseInt(5),
          id: id,
          size: size,
        });
        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("Design_Message_2"),
          color: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.con-vs-popup.fullscreen .vs-popup {
  width: auto !important;
  height: auto !important;
  .popupImage {
    width: 100%;
    height: auto;
  }
}
</style>

<style>
.vipFreeImage {
  position: absolute;
  z-index: 99;
  right: 5%;
  top: 5%;
}

.avatar {
  border-radius: 50%;
  width: 3rem;
  height: auto;
  cursor: pointer;
}
@media screen and (min-width: 900px) {
  .forSmallScreen {
    display: none !important;
  }
}
@media screen and (max-width: 900px) {
  .forNormalScreen {
    display: none !important;
  }
}

.vs-input-number .vs-input-number--input {
  min-width: 15px;
  padding: 0px;
}

.smallScreenFontSize {
  font-size: 11px;
}

.cartList {
  width: 100%;
  background: #fff;
  border-radius: 0.5rem;
  display: block;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  position: relative;
}
.cartList2 {
  width: 100%;
  background: #ff9f43aa;
  border-radius: 0.5rem;
  display: block;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  position: relative;
}
</style>
